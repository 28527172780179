import Vue from "vue";
import VueRouter from "vue-router";

import NotFound from "./views/404.vue";
import Home from "./components/home.vue";
import homeMain from "./views/homeMain";
import categoryProducts from "./views/categoryProducts.vue";
import productView from "./views/productView.vue";
import aboutUs from "./views/aboutUs";
import contactUs from "./views/contactUs";
import trackOrders from "./views/trackOrders";
import cart from "./views/cart";
import payment from "./views/payment.vue";
import checkout from "./views/checkout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "",
        component: homeMain,
      },
      {
        path: "/categories",
        component: categoryProducts,
      },
      {
        path: "/products",
        component: productView,
      },
      {
        path: "/payment",
        component: payment,
      },
      {
        path: "/checkout",
        component: checkout,
      },
      {
        path: "/contactUs",
        component: contactUs,
      },
      {
        path: "/aboutUs",
        component: aboutUs,
      },
      {
        path: "/trackOrders",
        component: trackOrders,
      },
      {
        path: "/cart",
        component: cart,
      },
    ],
  },
  {
    name: "NotFound",
    path: "**",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

export default router;
