<template>
  <div style="padding-bottom: 50px">
    <div class="homeHero">
      <h2>
        We deliver affordable, high-protein nutrition bars to your doorstep
        anywhere in Germany.
      </h2>
    </div>

    <div class="headerTitle">
      <h2>
        FEATURED
        <span style="color: var(--color-primary); font-weight: 700"
          >COLLECTIONS</span
        >
      </h2>

      <div class="hr">
        <hr style="border: 2px solid var(--color-primary); width: 3px" />
        <hr style="border: 2px solid var(--color-primary); width: 3px" />
        <hr style="border: 2px solid var(--color-primary); width: 3px" />
        <hr style="border: 2px solid var(--color-primary); width: 100px" />
      </div>
    </div>

    <div class="categories">
      <div v-if="categoriesLoading" class="loader">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>

      <div v-else class="category-cards">
        <div
          v-for="category in categories"
          :key="category.id"
          class="category-card"
          @click="viewCategory(category.id)"
        >
          <img :src="category.image" alt="category" />
          <h3>{{ category.name }}</h3>

          <button>View Products</button>
        </div>
      </div>
    </div>

    <div class="headerTitle">
      <h2>
        TOP
        <span style="color: var(--color-primary); font-weight: 700"
          >INTERESTING</span
        >
      </h2>

      <div class="hr">
        <hr style="border: 2px solid var(--color-primary); width: 3px" />
        <hr style="border: 2px solid var(--color-primary); width: 3px" />
        <hr style="border: 2px solid var(--color-primary); width: 3px" />
        <hr style="border: 2px solid var(--color-primary); width: 100px" />
      </div>
    </div>

    <div class="products">
      <div v-if="productsLoading" class="loader">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>

      <div v-else class="product-cards">
        <div
          v-for="product in products"
          :key="product.id"
          class="product-card"
          @click="viewProduct(product.id)"
        >
          <span
            class="stock"
            v-if="product.in_stock > 0"
            style="background-color: green"
          >
            In Stock
          </span>
          <span v-else class="stock" style="background-color: red">
            Out of Stock
          </span>
          <img :src="product.images[0].img" alt="product" />
          <h3>{{ product.name }}</h3>
          <p>
            <span
              v-if="product.discount_price && product.discount_price > 0"
              style="
                text-decoration: line-through;
                color: red;
                margin-right: 5px;
              "
              >£{{ product.discount_price }}</span
            >
            £{{ product.price }}
          </p>

          <button>View Product</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from "@/utils/supabase";

export default {
  name: "homeMain",
  mounted() {
    this.getCategories();
    this.getProducts();
  },
  data() {
    return {
      categoriesLoading: false,
      productsLoading: false,
      categories: [],
      products: [],
    };
  },
  methods: {
    viewCategory(id) {
      this.$router.push("/categories?id=" + id);
    },
    viewProduct(id) {
      if (!id) return;
      this.$router.push("/products?id=" + id);
    },

    async getCategories() {
      this.categoriesLoading = true;
      const { data, error } = await supabase.from("categories").select("*");
      if (error) {
        console.log(error);
      } else {
        Promise.all(
          data.map(async (category) => {
            const { data, error } = await supabase.storage
              .from("categories")
              .getPublicUrl(category.image);
            if (error) {
              console.log(error);
            } else {
              category.image = data.publicUrl;
            }
          })
        );

        this.categories = data;
        this.categoriesLoading = false;
      }
    },

    async getProducts() {
      this.productsLoading = true;
      const { data, error } = await supabase
        .from("products")
        .select("*, category(*)")
        .order("id", { ascending: false })
        .limit(10);
      if (error) {
        console.log(error);
      } else {
        Promise.all(
          data.map(async (product) => {
            Promise.all(
              product.images.map(async (image) => {
                const { data, error } = await supabase.storage
                  .from("products")
                  .getPublicUrl(image.img);
                if (error) {
                  console.log(error);
                } else {
                  image.img = data.publicUrl;
                }
              })
            );
          })
        );

        this.products = data;
        this.productsLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--color-primary);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

/* homeHero */
.homeHero {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/images/Proteinbar.webp");
  background-position: center;
}

.homeHero h2 {
  width: 100%;
  text-align: center;
  font-family: "Caveat", Sans-serif;
  color: black;
  font-size: 28px;
  line-height: 30px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.4);
}

.headerTitle {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerTitle h2 {
  font-weight: 300;
  font-size: 24px;
}

.hr {
  display: flex;
  margin-top: 10px;
}

.hr hr {
  border-radius: 5px;
  margin: 0px 5px;
}

/* categories */
.categories {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

.category-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.category-card {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
}

.category-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.3s;
}

.category-card img:hover {
  transform: scale(1.1);
}

.category-card h3 {
  margin-top: 10px;
  font-size: 18px;
}

.category-card button {
  width: 90%;
  margin-top: 10px;
  padding: 8px 0px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
  transition: 0.3s;
  font-size: 15px;
}

.category-card button:hover {
  transform: scale(1.1);
}

/* products */
.products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

.product-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  position: relative;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
}

.stock {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  z-index: 99;
}

.product-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.3s;
}

.product-card img:hover {
  transform: scale(1.1);
}

.product-card h3 {
  font-size: 16px;
  overflow: hidden;
}

.product-card p {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.product-card p span {
  font-size: 16px;
  font-weight: normal;
}

.product-card button {
  width: 90%;
  padding: 8px 0px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
  transition: 0.3s;
  font-size: 15px;
}

.product-card button:hover {
  transform: scale(1.1);
}
/* RESPONSIVE */

@media only screen and (max-width: 500px) {
  .category-cards,
  .product-cards {
    gap: 10px;
  }

  .category-card,
  .product-card {
    max-width: 155px;
  }

  .category-card img,
  .product-card img {
    height: 150px;
  }

  .category-card h3,
  .product-card h3 {
    font-size: 11px;
  }

  .product-card p {
    font-size: 16px;
  }

  .product-card button,
  .category-card button {
    padding: 5px 0px;
    font-size: 11px;
  }

  .stock {
    padding: 3px 5px;
    font-size: 10px;
  }
}
</style>
