<template>
  <div>
    <v-card
      id="trackForm"
      class="mx-auto pa-4 mt-10"
      style="width: 85%; max-width: 700px"
    >
      <div class="headerTitle">
        <h2>
          Track
          <span style="color: var(--color-primary); font-weight: 700"
            >Order</span
          >
        </h2>

        <div class="hr">
          <hr style="border: 2px solid var(--color-primary); width: 3px" />
          <hr style="border: 2px solid var(--color-primary); width: 3px" />
          <hr style="border: 2px solid var(--color-primary); width: 3px" />
          <hr style="border: 2px solid var(--color-primary); width: 100px" />
        </div>
      </div>

      <v-form lazy-validation ref="form" @submit.prevent="loadData">
        <v-col class="mb-n4">
          <v-text-field
            :rules="[required()]"
            label="Order Id"
            v-model="orderId"
            required
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col class="mb-n4">
          <v-text-field
            :rules="[required(), email()]"
            label="Billing Email"
            v-model="orderEmail"
            required
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            dark
            style="background-color: var(--color-primary)"
            type="submit"
            elevation="0"
            >Track</v-btn
          >
        </v-col>
      </v-form>
    </v-card>

    <LoadingDialog v-model="loader" message="Please wait" />
  </div>
</template>

<script>
import { required, email } from "@/utils/validators";
import LoadingDialog from "../components/LoadingDialog.vue";

export default {
  data() {
    return {
      loader: false,
      orderId: null,
      orderEmail: null,
    };
  },
  methods: {
    email,
    required,

    async loadData() {
      try {
        if (this.$refs.form.validate()) {
          this.loader = true;
          setTimeout(() => {
            this.loader = false;
            this.$alert("Please enter a valid order ID", "Error", "error");
          }, 2000);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: {
    LoadingDialog,
  },
};
</script>

<style>
.headerTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.headerTitle h2 {
  font-weight: 300;
  font-size: 24px;
}

.hr {
  display: flex;
  margin-top: 10px;
}

.hr hr {
  border-radius: 5px;
  margin: 0px 5px;
}
</style>
