<template>
  <div class="main">
    <v-dialog v-model="loading" max-width="600" width="200" persistent>
      <v-card
        style="
          padding: 20px;
          text-align: left;
          display: flex;
          align-items: center;
        "
      >
        <v-progress-circular indeterminate color="primary" />
        <p style="margin-left: 20px; margin-bottom: 0">Please wait</p>
      </v-card>
    </v-dialog>

    <div class="billingDetails">
      <v-form @submit.prevent="placeOrder" ref="form" lazy-validation>
        <v-card-title>BILLING DETAILS</v-card-title>
        <v-col class="mb-n4">
          <v-text-field
            :rules="[required()]"
            v-model="orderDetails.first_name"
            label="First Name"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col class="mb-n4">
          <v-text-field
            :rules="[required()]"
            v-model="orderDetails.last_name"
            label="Last Name"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col class="mb-n4">
          <!-- <v-autocomplete
            :rules="[required()]"
            v-model="orderDetails.country"
            label="Country"
            :items="countries"
            outlined
            @change="getStates"
            disabled
            dense
          >
          </v-autocomplete> -->

          <v-text-field
            :rules="[required()]"
            v-model="orderDetails.country"
            label="Country"
            outlined
            dense
            disabled
          >
          </v-text-field>
        </v-col>

        <v-col class="mb-n4">
          <v-autocomplete
            :rules="[required()]"
            v-model="orderDetails.state"
            :loading="statesLoading"
            label="State"
            :items="states"
            item-text="name"
            @change="getCities"
            outlined
            :disabled="statesLoading || !orderDetails.country"
            dense
          >
          </v-autocomplete>
        </v-col>

        <v-col class="mb-n4">
          <v-autocomplete
            :rules="[required()]"
            v-model="orderDetails.city"
            :loading="citiesLoading"
            label="City"
            :items="cities"
            item-text="name"
            outlined
            :disabled="
              citiesLoading || !orderDetails.country || !orderDetails.state
            "
            dense
          >
          </v-autocomplete>
        </v-col>

        <v-col class="mb-n4">
          <v-text-field
            :rules="[required()]"
            v-model="orderDetails.street"
            label="Street Address"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col class="mb-n4">
          <v-text-field
            v-model="orderDetails.street2"
            label="Street Address 2 (Optional)"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col class="mb-n4">
          <v-text-field
            :rules="[required()]"
            v-model="orderDetails.post_code"
            label="Postcode"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col class="mb-n4">
          <v-text-field
            :rules="[required()]"
            type="number"
            v-model="orderDetails.phone"
            label="Phone"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col class="mb-n4">
          <v-text-field
            :rules="[required(), email()]"
            type="email"
            v-model="orderDetails.email"
            label="Email Address"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn dark @click="placeOrder" color="primary" elevation="0"
            >Next</v-btn
          >
        </v-col>
      </v-form>
    </div>
  </div>
</template>

<script>
import { supabase } from "@/utils/supabase";
import { required, email } from "../utils/validators";
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      orderDetails: {
        first_name: null,
        last_name: null,
        country: "Germany",
        street: null,
        street2: null,
        state: null,
        city: null,
        post_code: null,
        phone: null,
        email: null,
        cart: null,
      },
      countryLoading: false,
      statesLoading: false,
      citiesLoading: false,
      countries: [],
      states: [],
      cities: [],
    };
  },
  methods: {
    required,
    email,

    async placeOrder() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const { error } = await supabase
          .from("order_details")
          .insert([this.orderDetails]);

        if (error) {
          console.log(error);
          this.loading = false;
        } else {
          localStorage.setItem(
            "paymentInfo",
            JSON.stringify(this.orderDetails)
          );
          this.loading = false;
          this.$router.push("/checkout");
        }
      }
    },

    async getCountries() {
      try {
        this.countryLoading = true;
        const countries = await axios.get(
          "https://countriesnow.space/api/v0.1/countries"
        );
        this.countries = countries.data.data;
        this.countries = this.countries.map((data) => data.country);
      } catch (e) {
        console.log(e);
      }
      this.countryLoading = false;
    },

    async getStates() {
      try {
        this.statesLoading = true;

        const states = await axios.post(
          "https://countriesnow.space/api/v0.1/countries/states",
          {
            country: this.orderDetails.country,
          }
        );
        this.states = states?.data?.data?.states.map((state) => state?.name);

        this.statesLoading = false;
      } catch (e) {
        console.log(e);
      }
      this.statesLoading = false;
    },

    async getCities() {
      try {
        this.citiesLoading = true;

        const cities = await axios.post(
          "https://countriesnow.space/api/v0.1/countries/cities",
          {
            country: this.orderDetails.country,
          }
        );

        this.cities = cities?.data?.data;

        this.citiesLoading = false;
      } catch (e) {
        console.log(e);
        this.citiesLoading = false;
      }
    },

    loadCarts() {
      this.orderDetails.cart = JSON.parse(localStorage.getItem("cart"));
    },
  },
  mounted() {
    this.getStates();

    this.loadCarts();
  },
};
</script>

<style scoped>
.main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.billingDetails {
  width: 60%;
  padding: 0px 10px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .billingDetails {
    width: 100%;
  }
}
</style>
