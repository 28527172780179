<template>
  <div>
    <div class="aboutHeader">
      <h2>Contact Us</h2>
      <div class="aboutHeader-link">
        <p>
          <router-link style="text-decoration: none" to="/">
            <v-icon style="margin-top: -4px"> mdi-home </v-icon>
          </router-link>
          <router-link
            style="text-decoration: none; color: black"
            to="/contactUs"
          >
            > Contact Us
          </router-link>
        </p>
      </div>
    </div>

    <div>
      <v-card class="mx-auto my-12 pa-10" max-width="1000">
        <div class="headerTitle">
          <h2>
            Contact
            <span style="color: var(--color-primary); font-weight: 700"
              >US</span
            >
          </h2>

          <div class="hr">
            <hr style="border: 2px solid var(--color-primary); width: 3px" />
            <hr style="border: 2px solid var(--color-primary); width: 3px" />
            <hr style="border: 2px solid var(--color-primary); width: 3px" />
            <hr style="border: 2px solid var(--color-primary); width: 100px" />
          </div>
        </div>

        <v-form lazy-validation ref="form" @submit.prevent="submit">
          <v-col class="mb-n4">
            <v-text-field
              :rules="[required()]"
              label="Your Name"
              v-model="yourName"
              required
              outlined
              dense
            >
            </v-text-field>
          </v-col>

          <v-col class="mb-n4">
            <v-text-field
              :rules="[required(), email()]"
              label="Your Email"
              v-model="yourEmail"
              required
              outlined
              dense
            >
            </v-text-field>
          </v-col>

          <v-col class="mb-n4">
            <v-text-field
              :rules="[required()]"
              label="Subject"
              v-model="subject"
              required
              outlined
              dense
            >
            </v-text-field>
          </v-col>

          <v-col class="mb-n4">
            <v-textarea
              label="Your Message (Optional)"
              v-model="message"
              required
              outlined
              dense
            >
            </v-textarea>
          </v-col>

          <v-col>
            <p v-show="success" style="color: var(--color-primary)">
              Message send Succesfully!
            </p>
            <p v-show="err" style="color: red">
              Something went wrong! Please try again later
            </p>
          </v-col>

          <v-col class="d-flex justify-end">
            <v-btn
              dark
              style="background-color: var(--color-primary)"
              type="submit"
              elevation="0"
              >Submit</v-btn
            >
          </v-col>
        </v-form>
      </v-card>
    </div>

    <LoadingDialog v-model="loader" message="Sending" />
  </div>
</template>

<script>
import { required, email } from "@/utils/validators";
import LoadingDialog from "../components/LoadingDialog.vue";
import { supabase } from "@/utils/supabase";

export default {
  data() {
    return {
      yourName: null,
      yourEmail: null,
      subject: null,
      message: null,
      err: false,
      success: false,
      loader: false,
    };
  },
  methods: {
    email,
    required,

    async submit() {
      if (this.$refs.form.validate()) {
        this.loader = true;

        const { error } = await supabase.from("contact_us").insert([
          {
            name: this.yourName,
            email: this.yourEmail,
            subject: this.subject,
            message: this.message,
          },
        ]);

        if (error) {
          this.loader = false;
          this.success = false;
          this.err = true;
          this.alert = "Something went wrong! Please try again later";
        } else {
          this.loader = false;
          this.success = true;
          this.err = false;
          this.$refs.form.reset();
        }
      }
    },
  },
  components: {
    LoadingDialog,
  },
};
</script>

<style scoped>
.headerTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.headerTitle h2 {
  font-weight: 300;
  font-size: 24px;
}

.hr {
  display: flex;
  margin-top: 10px;
}

.hr hr {
  border-radius: 5px;
  margin: 0px 5px;
}
</style>
