<template>
  <div>
    <div v-if="loading" class="loader">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>

    <div v-else>
      <div class="aboutHeader">
        <h3>
          {{ (products.length > 0 && products[0].category.name) || "" }}
        </h3>
        <div class="aboutHeader-link">
          <p>
            <router-link style="text-decoration: none" to="/">
              <v-icon style="margin-top: -4px"> mdi-home </v-icon>
            </router-link>

            <span style="text-decoration: none; color: black">
              > {{ (products.length > 0 && products[0].category.name) || "" }}
            </span>
          </p>
        </div>
      </div>

      <div class="headerTitle">
        <h2>
          {{
            (products.length > 0 && products[0].category.name.split(" ")[0]) ||
            "No Products available"
          }}
          <span style="color: var(--color-primary); font-weight: 700">
            {{
              (products.length > 0 &&
                products[0].category.name.split(" ")[1]) ||
              ""
            }}
          </span>
        </h2>

        <div class="hr">
          <hr style="border: 2px solid var(--color-primary); width: 3px" />
          <hr style="border: 2px solid var(--color-primary); width: 3px" />
          <hr style="border: 2px solid var(--color-primary); width: 3px" />
          <hr style="border: 2px solid var(--color-primary); width: 100px" />
        </div>
      </div>

      <div class="products" v-if="!loading && products.length > 0">
        <div class="product-cards">
          <div
            v-for="product in products"
            :key="product.id"
            class="product-card"
            @click="viewProduct(product.id)"
          >
            <span
              class="stock"
              v-if="product.in_stock > 0"
              style="background-color: green"
            >
              In Stock
            </span>
            <span v-else class="stock" style="background-color: red">
              Out of Stock
            </span>
            <img :src="product.images[0].img" alt="product" />
            <h3>{{ product.name }}</h3>
            <p>
              <span
                v-if="product.discount_price && product.discount_price > 0"
                style="
                  text-decoration: line-through;
                  color: red;
                  margin-right: 5px;
                "
                >£{{ product.discount_price }}</span
              >
              £{{ product.price }}
            </p>

            <button>View Product</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from "@/utils/supabase";

export default {
  name: "CategoryProducts",
  watch: {
    $route() {
      this.getProducts();
    },
  },
  mounted() {
    this.getProducts();
  },
  data() {
    return {
      products: [],
      loading: false,
    };
  },
  methods: {
    viewProduct(id) {
      this.$router.push("/products?id=" + id);
    },
    async getProducts() {
      this.loading = true;

      const { data, error } = await supabase
        .from("products")
        .select("*, category(*)")
        .eq("category", this.$route.query.id)
        .order("id", { ascending: false });
      if (error) {
        console.log(error);
      } else {
        Promise.all(
          data.map(async (product) => {
            Promise.all(
              product.images.map(async (image) => {
                const { data, error } = await supabase.storage
                  .from("products")
                  .getPublicUrl(image.img);
                if (error) {
                  console.log(error);
                } else {
                  image.img = data.publicUrl;
                }
              })
            );
          })
        );

        this.products = data;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
/* loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--color-primary);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.headerTitle {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerTitle h2 {
  font-weight: 300;
  font-size: 24px;
}

.hr {
  display: flex;
  margin-top: 10px;
}

.hr hr {
  border-radius: 5px;
  margin: 0px 5px;
}

.products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

.product-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  position: relative;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
}

.stock {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  z-index: 99;
}

.product-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.3s;
}

.product-card img:hover {
  transform: scale(1.1);
}

.product-card h3 {
  font-size: 16px;
  overflow: hidden;
}

.product-card p {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.product-card p span {
  font-size: 16px;
  font-weight: normal;
}

.product-card button {
  width: 90%;
  padding: 8px 0px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
  transition: 0.3s;
  font-size: 15px;
}

.product-card button:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 500px) {
  .product-cards {
    gap: 10px;
  }

  .product-card {
    max-width: 155px;
  }

  .product-card img {
    height: 150px;
  }

  .product-card h3 {
    font-size: 11px;
  }

  .product-card p {
    font-size: 16px;
  }

  .product-card button {
    padding: 5px 0px;
    font-size: 11px;
  }

  .stock {
    padding: 3px 5px;
    font-size: 10px;
  }
}
</style>
